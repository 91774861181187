/* GradedBar.css */

.graded-bar {
    width: 6em;
    height: 1.5em;
    background: #e0e0e0; /* Light gray background */
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin-left: 1em;
  }
  
  .graded-bar-inner {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  