
.selectors-container-center {
    width: 100%!important;
    display: flex;
    justify-content: center!important;
    margin-bottom: 2em!important;
}

.selectors-sub-container {
    text-align: center;
}
.lifecycle-health-data-container {
    display: flex;
    justify-content: space-evenly;
    padding: 1em;
    height: auto;  
}

.lifecycle-chart-container {
    width: 20rem;
    height: 14rem;
    margin: auto;
}
@media (max-width: 600px) {
    .lifecycle-chart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}


.lifecycle-legend {
    display: flex;
    justify-content: space-around;
    padding: 1em;
    width: 100%;
}

.lifecycle-legend-item-value-healthy {
    font-size: 1.6rem;
    color: greenyellow;
    text-align: center;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-value-healthy {
        font-size: 1.2rem;
    }
}
.lifecycle-legend-item-value-critical {
    font-size: 1.6rem;
    color: orange;
    text-align: center;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-value-critical {
        font-size: 1.2rem;
    }
}
.lifecycle-legend-item-value-expired {
    font-size: 1.6rem;
    color: red;
    text-align: center;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-value-expired {
        font-size: 1.2rem;
    }
}

.lifecycle-legend-item-label {
    font-size: 0.8rem;
    color: #ccc;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-label {
        font-size: 0.7rem;
    }
}

.lifecycle-legend-item-value-total {
    font-size: 1.8rem;
    color: #fafafa;
    text-align: center;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-value-total {
        font-size: 1.2rem;
    }
}

.lifecycle-legend-item-label-total {
    font-size: 0.9rem;
    color: #ccc;
    text-align: center;
    margin-bottom: 1em;
}
@media (max-width: 600px) {
    .lifecycle-legend-item-label-total {
        font-size: 0.9rem;
    }
    .lifecycle-legend-item-value-total-container {
        margin-bottom: 2rem;
    }
}

.lifecycle-chart-footer {
    text-align: center;
    margin: 0.5em 0;
    font-size: 1.1em;
}
@media (max-width: 600px) {
    .lifecycle-chart-footer {
        font-size: 0.9em;
    }
}

.lifecycle-icons-container {
    display: grid;
    height: 14rem;
}
@media (max-width: 600px) {
    .lifecycle-icons-container {
        height: 17rem;
    }
}

.lifecycle-heart-image {
    height: 3em;
    margin-top: 3.5em;
}

.lifecycle-time-image {
    height: 3em;
    margin-top: 22em;
}

.lifecycle-info-select-sub-label {
    padding: .3rem;
    font-size: .75rem;
    margin-left: 0.5rem;
    color: #ccc;
  }
    @media (max-width: 600px) {
        .lifecycle-info-select-sub-label-small-screen {
        margin-top: 22rem!important;
        } 
    } 
  
  @media (max-width: 600px) {
    .lifecycle-info-select-sub-label {
      padding: .3rem;
      font-size: .75rem;
      margin-left: 0.5rem;
      font-size: 0.8em;
    } 
  }
  
  .center-align-chart {
    text-align: center;
    margin: auto; /* Center align the chart horizontally */
    height: 14rem
}
@media (max-width: 600px) {
    .center-align-chart {
        height: auto;
    }
}