.superAdmin-container {
    height: auto;
    margin-left: 15%;
}
@media(max-width: 600px) {
    .superAdmin-container {
        margin-left: 0;
    }
}
 
.super-admin-nav-container {
    background-color: #071d2c;
    padding: 1em;
    margin-top: 5em;
}
@media(max-width: 600px) {
    .super-admin-nav-container {
       display: grid;
      justify-content: flex-start;
      
    } 
}


.super-admin-nav-toggle {
    background-color: #F49523;
}
@media(max-width: 999px) {
    .super-admin-nav-toggle {
        width: fit-content;
    }
}

#basic-navbar-nav {
    text-align: start;
}

.super-admin-nav-link {
    color: #fafafa;
    font-size: 0.9em;
    cursor: pointer;
    text-decoration: none;
}
@media(max-width: 999px) {
    .super-admin-nav-link {
        text-align: start;
    }
}

.super-admin-nav-link:active {
    color: inherit; /* Prevent the color from changing on interaction */
  }
.super-admin-nav-link:hover {
    color: #F49523;
}

