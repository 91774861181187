.banner {
    margin-left: 320px;
    width: 900px;
    /* height: 100px; */
    padding: 20px;
    background-color:#4c4b51;
    color: white;
  }
  
.banner-closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.banner-clickbtn {
  cursor: pointer;
}