.footer-text {
   font-size: small;
}
@media (max-width: 600px) {
    .footer-text {
        font-size: xx-small;
    } 
}
@media (min-width: 601px) and (max-width: 1600px) {
    .footer-text {
        font-size: x-small;
    }
}
@media (min-width: 1601px) {
    .footer-text {
        font-size: 0.77em;
    }
}

.footer-copyright {
    padding: 0.3rem;
    font-size: small;
}
@media (max-width: 600px) {
    .footer-copyright {
        font-size: xx-small;
    } 
}
@media (min-width: 601px) and (max-width: 1600px) {
    .footer-copyright {
        font-size: x-small;
    }
}
@media (min-width: 1601px) {
    .footer-copyright {
        font-size: 0.75em;
    }
}

.footer-container {    
    display: flex;
    justify-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
    color: white;
    margin: 0;
    padding: 2em;
    margin-left: 15%;
     
} 

@media (max-width: 600px) {
    .footer-container {    
        display: flex;
        text-align: center;
        align-items: center;
        color: white;
        margin-top: 0;
        padding: 0em;
        margin-left: 0%;
    } 
}


.footer-url-text {
    color: #F49523;
}


