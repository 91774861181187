.lifecycle-info-container-center {
    text-align: center;
    padding: 3em 5em 0em 5em!important;
    height: 100%!important;
}
@media (max-width: 600px) {
    .lifecycle-info-container-center {
        text-align: center;
        padding: 0.5em 2em 0em 2em!important;
        height: 100%;
    }
    
}


@media (max-width: 600px) {
  .lifecycle-info-welcome-text-new {
    font-size: 0.8rem!important;
  }
}

@media (max-width: 600px) {
  .lifecycle-health-data-container-new {
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.toggle-swich-container-new {
  margin-top: 22rem!important;
  margin-left: -1em!important;
 }

@media (max-width: 600px) {
 .toggle-swich-container-new {
  margin-top: 20rem!important;
 }
}


/* Apply styles to the table container */
 
.table-wrapper-new {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 33em; /* Set a maximum height for the table */ 
  background-color: #071d2c;
}

.table-wrapper-new-content {
  table-layout: fixed;
  width: 100%;
}

.table-wrapper-new th {
  background-color: #071d2c;
}
/* Change scrollbar color */
.table-wrapper-new::-webkit-scrollbar {
  width: 0.9em;
}
@media (max-width: 600px) {
  .table-wrapper-new::-webkit-scrollbar {
    width: 0.5em; 
    height: 0.5em;
  }
}

.table-wrapper-new::-webkit-scrollbar-thumb {
  background: #334066; /* Color of the thumb */    
}

.table-wrapper-new::-webkit-scrollbar-corner {
  background: #334066; /* Color of the thumb */    
}

.table-wrapper-new th {
  background-color: #071d2c;
}


  .table-container-lifecycle-camera-new::-webkit-scrollbar {
    width: 0.5em!important;
    height: 0.5em!important;
  }



