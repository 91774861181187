.health-info-sidebbar {
  margin-top: -3.2em;
}

.welcome-container {
 padding: .3em;
 margin-bottom: 1rem;
 margin-left: 0rem;
}
@media(max-width: 600px) {
  .welcome-container {
    padding: .3em;
    margin-bottom: 1rem;
    margin-left: 0rem;
   }
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 300px; /* Set a maximum height for the table */
  background-color: #071d2c;
}
.table-container-map {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 300px; /* Set a maximum height for the table */
  background-color: #071d2c;
  font-size: 0.7rem;
}
/* Change scrollbar color */
.table-container::-webkit-scrollbar {
  width: 13px; /* Width of the scrollbar */
}
.table-container::-webkit-scrollbar-track {
  background: #000; /* Color of the track */
}
.table-container::-webkit-scrollbar-thumb {
  background: #334066;
 /* Color of the thumb */
}

.table-container-device {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 33em; /* Set a maximum height for the table */ 
  background-color: #334066;
}
.table-container-device th {
  background-color: #334066;
}
/* Change scrollbar color */
.table-container-device::-webkit-scrollbar {
  width: 13px; /* Width of the scrollbar */
}

.table-container-device::-webkit-scrollbar-thumb {
  background: #202841; /* Color of the thumb */
  
}

.table-container-unackalerts {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 33em; /* Set a maximum height for the table */
  background-color: #071d2c;
}
@media (max-width: 600px) {
  .table-container-unackalerts {
    overflow-x: auto; /* Enable horizontal scrolling when needed */
    max-height: 33em; /* Set a maximum height for the table */
    background-color: #071d2c;  
  }
}


table {
  width: 100%;
  border-collapse: collapse;
  font-size: small;
}
@media (max-width: 600px) {  
table {
  width: 100%;
  border-collapse: collapse;
  font-size: x-small;
}
}

th {
  padding: 8px;
  text-align: left;
  color: gray; 
  font-size: 1.1em;
  background-color: #071d2c;  
}
@media (max-width: 600px) {
  th {
    padding: 8px;
    text-align: left;
    color: gray; 
    font-size: x-small;
    background-color: #212529; 
  }
}

td {
  border-bottom: 1px solid  #ccc;
  padding: 8px;
  text-align: left;
  color: #ccc;  
}

thead { 
  border-collapse: collapse; /* This ensures that adjacent borders merge together */
  border-bottom: 1px solid #ccc; /* Change 'red' to the color you want */
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top of the container */ 
  z-index: 1;
}

tbody {
  overflow-y: scroll; /* Enable vertical scrolling */
  max-height: 300px; /* Set a maximum height for the table body */
  
}

.modal-close {
  font-size: x-large;
  color: #bc358b;
  font-size: xlarger;
  position: relative;
  background-color: transparent;
  border: none;

}
.modal-close:hover {
  color: #e9426C;
}


select {
  width: auto;
  padding: 5px;
  font-size: auto;
  line-height: 1;
  border: 0.5em;
  border-radius: 5px;
  height: 30px;
  
}
.siteDropdown {
  position: relative;
  display: inline-block;
}

.siteDropdown select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #ccc;
  padding: 18px;
  font-size: 14px;
  border-radius: 12px;
  background-color: white;
  width: 200px;
  background-image: url("../images/dropdown.png"); /* Replace with your arrow icon */
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  cursor: pointer;
}

.siteDropdown select::-ms-expand {
  display: none;
}
.siteDropdown select:hover {
  border-color: #aaa;
}
.siteDropdown select:focus {
    outline: none;
    border-color: #555;
}
.siteDropdown option { 
    background-color: white;
    color: #333;
}

/* For port layout */
.view-port-btn {
  cursor: pointer;
  text-align: right;
  margin-right: 2.5rem;
}
@media (max-width: 680px) {
  .view-port-btn {
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    font-size: small;
  }
}

.view-port-btn-disabled {
  text-align: right;
  margin-right: 2.5rem;
  opacity: 0.5;
}
@media (max-width: 680px) {
  .view-port-btn-disabled  {
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    font-size: small;
  }
}

.switches-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow-x: auto;
  /* max-height: 15rem; */
  padding-bottom: 2rem;
  justify-content: center;
}

.switches-container::-webkit-scrollbar {
  width: 13px;
}

.switches-container::-webkit-scrollbar-track {
  background: #334066;
}

.switches-container::-webkit-scrollbar-thumb {
  background: #202841;
}

@media (max-width: 680px) {
  .switches-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    justify-content: normal;
  }
}

.switch-server-name {
  font-size: 0.7rem;
}
@media (max-width: 680px) {
  .switch-server-name {
    font-size: 0.6rem;
  }
}

.switch-site-name {
  font-size: 0.7rem;
  margin-right: 1rem;
  margin-top: -6rem;
  width: 2.5rem;
}
@media (max-width: 680px) {
  .switch-site-name {
    font-size: 0.6rem;
    margin-bottom: 1rem;
  }
}

.switch-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
}
@media (max-width: 680px) {
  .switch-container {
    margin-top: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.ports-container {
  height: 15rem;
}

.ports-top-half {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.ports-bottom-half {
  display: flex;
  flex-direction: row;
}

.port-num-img {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.port-name {
  display: none; 
  font-size: 0.5rem;
  color: #00008B;
  background-color: #fff;
  padding: 0.2rem;
  border-radius: 0.3rem;
  width: 3.4rem; 
  max-width: 50rem;
}

.toast-outcome {
  font-size: 12px;
}

.port-camera-outcome {
  color: #071d2c;
}

.port-icon-name {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.port-icon-name:hover .port-name {
  display: block;
}

.port-text {
  font-size: 0.8rem;
  text-align: center;
}


.port-icon {
  height: 2.4rem;
  background-color: white;
  padding-bottom: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  cursor: pointer;
  object-fit: cover;
}
@media (max-width: 680px) {
  .port-icon {
    height: 2rem;
    cursor: pointer;
  }
}

.port-icon-onclick-disabled {
  height: 2.4rem;
  background-color: white;
  padding-bottom: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
  object-fit: cover;
  opacity: 0.5;

}
@media (max-width: 680px) {
  .port-icon-onclick-disabled {
    height: 2rem;
  }
}

.port-icon-unconnected {
  height: 2.4rem;
  background-color: white;
  padding-bottom: 0.1rem;
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
@media (max-width: 680px) {
  .port-icon-unconnected {
    height: 2rem;
  }
}

.custom-modal .modal-content {
  padding: 0 !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.table-container-outcome {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 30rem; /* Set a maximum height for the table */
  background-color: #334066;
}

#outcome-table th{
  font-size: 0.8rem;
}

@media (max-width: 680px) {
  #outcome-table th{
    font-size: 0.6rem;
  }
}


.table-container-outcome th {
  background-color: #334066;
  border-bottom: 2px solid #ccc;
}

.health-cards-container {
  margin-top: 2rem;
}
@media (max-width: 680px) {
  .health-cards-container {
    margin-top: 0;
  }
}

.health-cards {
  background-color: #384670;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
  border-radius: .5em;
  width: 100%;
  margin: auto;
  padding: auto;
  margin-bottom: 1em; 
  padding: 1.3rem 0;
  }
@media (max-width: 600px) {
  .health-cards {
    background-color: slategray;
    border-radius: .5em;
    width: 100%;
    margin: auto;
    padding: auto;
    margin-bottom: 1em; 
    padding: .8rem 0;
    }
}

#health-card-container {
 margin-left: 5%;
 margin-right: 5%;
 width: 90%;
}

#health-card-icon {
  font-size: 2.8rem;
  text-align: end;
}
@media (max-width: 680px) {
  #health-card-icon {
    font-size: 2.1rem;
    text-align: end;
  } 
}

#health-card-title {
  font-size: .8rem;
  color: #fafafa;
  opacity: 0.8;
}
@media (max-width: 680px) {
  #health-card-title {
    font-size: .68rem;
  } 
}

#health-card-content {
  font-size: 1.3rem;
  color: #fafafa;
}

#health-card-action {
  font-size: .65rem;
}

#health-card-action-btn {
  font-size: 1em;
  color: lightgray;
  padding: 0;
  border: 0;
}
@media (max-width: 600px) {  
#health-card-action-btn {
  font-size: xx-small;
  color: lightgray;
  padding: 0;
  border: 0;
}
}

#health-card-action-btn:hover {
  font-size: 1em;
  color: white;
}

.unAckAlertModal {
 border-width: 0;
 width: 130%; 
}
@media (max-width: 600px) {
  .unAckAlertModal {
    border-width: 0;
    width: 130%;
    overflow: auto;
   }
}

#unack-chkbox-container {
  margin-top: .2em;
  width: 8rem;
  height: 2em;
  border: 1px solid #EF5B5B;
  border-radius: .5em;
  padding: .2em .3em;
}
.custom-checkbox[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Set a custom checkbox size */
  width: 10px;
  height: 10px;
  border: 1px solid #EF5B5B;
}

.chart-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  padding: 0;
}
@media (max-width: 680px) {
  .chart-container {
    display: grid;
  } 
}

.doughnut-alert-icon {
  height: 1.5rem; 
  margin-top: -11rem;
}
@media (max-width: 680px) {
  .doughnut-alert-icon {
    height: 2.5rem;
    margin-top: -17rem;
  }
}

.tooltip-text-no-data {
  visibility: hidden;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-left: -2.3rem;
}
@media (max-width: 680px) {
  .tooltip-text-no-data {
    visibility: hidden;
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    margin-left: -2.7rem;
    margin-top: -1.5rem;
  }
}

.tooltip-text-all-healthy {
  visibility: hidden;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-left: -2.9rem;
}
@media (max-width: 680px) {
  .tooltip-text-all-healthy {
    visibility: hidden;
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    margin-left: -3.2rem;
    margin-top: -1.5rem;
  }
}

.tooltip-text-all-unhealthy {
  visibility: hidden;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-left: -3.4rem;
}
@media (max-width: 680px) {
  .tooltip-text-all-unhealthy {
    visibility: hidden;
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    margin-left: -3.8rem;
    margin-top: -1.5rem;
  }
}

.tooltip-text-some-unhealthy {
  visibility: hidden;
  font-size: 0.8rem;
  text-align: center;
  position: absolute;
  margin-left: -4rem;
}
@media (max-width: 680px) {
  .tooltip-text-some-unhealthy {
    visibility: hidden;
    font-size: 0.8rem;
    text-align: center;
    position: absolute;
    margin-left: -4.3rem;
    margin-top: -1.5rem;
  }
}

.tooltip-text-in-progress {
  visibility: hidden;
  font-size: 0.8rem;
  position: absolute;
  margin-left: -2.9rem;
}
@media (max-width: 680px) {
  .tooltip-text-in-progress {
    visibility: hidden;
    font-size: 0.8rem;
    position: absolute;
    margin-left: -3.3rem;
    margin-top: -1.5rem;
  }
}


.doughnut-alert-icon:hover+ .tooltip-text-no-data {
  visibility: visible;
  opacity: 1;
}

.doughnut-alert-icon:hover+ .tooltip-text-all-healthy {
  visibility: visible;
  opacity: 1;
}

.doughnut-alert-icon:hover+ .tooltip-text-all-unhealthy {
  visibility: visible;
  opacity: 1;
}

.doughnut-alert-icon:hover+ .tooltip-text-some-unhealthy {
  visibility: visible;
  opacity: 1;
}

.doughnut-alert-icon:hover+ .tooltip-text-in-progress {
  visibility: visible;
  opacity: 1;
}


.chart-values {
 position: absolute;
 top: 83%;
 left: 50%;
 transform: translate(-50%, -50%);
 display:grid
}
@media (max-width: 680px) {
  .chart-values {
    position: absolute;
    top: 72%;    
    transform: translate(-50%, -50%);
    display:grid
  }
}

.chart-offline-text {
  color: lightGray;
  font-size: .8vw;
}
@media (max-width: 680px) {
  .chart-offline-text {
    color: lightGray;
    font-size: x-small;
  }
}

.chart-offline-number {
  color: darkgrey;
  /* font-size: 4rem; */
  font-size: 1.5rem;
  /* margin-top: 2.3rem; */
}
/* @media (max-width: 680px) {
  .chart-offline-number {
    color: #EF5B5B;
    font-size: 2rem;   
  }
} */

.chart-offline-number-small {
  color: darkgrey;
  font-size: 1.5rem;
  /* margin-top: -1.3rem; */
}

.doughnut-small {
  position: relative; 
  text-align: center;
}

.bottom-toggles {
  margin-left:.35rem;
  color: #C8C8C8;
  font-size: small;
}
@media (max-width: 680px) {
  .bottom-toggles {
    margin-left: 0.35rem;
    color: #C8C8C8;
    font-size: .6rem;
  }
}

.bottom-toggles-arrow {
  color: #fafafa;
  height:.9rem;
}
@media (max-width: 680px) {
  .bottom-toggles-arrow {
    color: #fafafa;
    height:.7rem;
    margin-left: -.4em;
  }
}

.bottom-toggles-msg {
  color: #03C03C;
  font-size: small;
  margin-left: 0.4rem;
}


.healthy-sites-msg {
  color: #EF5B5B;
  font-size: small;
  margin-left: 0.4rem;
}

.no-data-avail-msg {
  font-size: small;
  margin-left: 0.4rem;
}

/*  health info banner */

.health-info-welcome-text {
  color: #fafafa;
  opacity: 0.9;
}
@media (max-width: 600px) {
  .health-info-welcome-text {
      font-size: medium;
      margin-top: 2em;
  } 
}
@media (min-width: 601px) and (max-width: 1600px) {
  .health-info-welcome-text {
      font-size: large;
  }
}
@media (min-width: 1601px) {
  .health-info-welcome-text {
      font-size: larger;
  }
}


@media (max-width: 600px) {
  .health-info-welcome-time {
    font-size: x-small;
    color: lightGray;
  } 
}
@media (min-width: 601px) and (max-width: 1600px) {
  .health-info-welcome-time {
    font-size: small;
    color: lightGray;
  }
}
@media (min-width: 1601px) {
  .health-info-welcome-time {
    font-size: smaller;
    color: lightGray;
  }
}


/* selectors container */
.selectors-container {
  display: flex;
  margin-top: 0.3rem;
  width: fit-content;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
@media (max-width: 600px) {
  .selectors-container {
    display: grid;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
    width: fit-content;
  }
}

.total-site-count {
  font-size: 0.7rem;
  margin-top: 0.4rem;
  margin-left: 0.2rem;
}

.chart-alert-icons-container {
  display: absolute;
  float: right;
}

.chart-alert-icon {
  height: 1.5rem;
}

/* select labels  */
.health-info-select-label {
  padding: .3rem;
  font-size: .75rem;

} 
@media (max-width: 600px) {
  .health-info-select-label {
    padding: .3rem;
    font-size: .75rem;
  } 
}
@media (min-width: 601px) and (max-width: 767px) {
  .health-info-select-label {
    padding: .3rem;
    font-size: smaller;
   
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  .health-info-select-label {
    padding: .3rem;
    font-size: medium;
  }
}
@media (min-width: 1601px) {
  .health-info-select-label {
    padding: .3rem;
    font-size: medium;
    
  }
}


/* dropdowns */
.health-info-dropdown {
  font-size: small;
  height:fit-content;
}
@media (max-width: 600px) {
  .health-info-dropdown {
    font-size: xx-small;
    height:fit-content;
    margin-left: 0.4rem;
  } 
}
@media (min-width: 601px) and (max-width: 767px) {
  .health-info-dropdown {
    font-size: x-small;
    height:fit-content;
  }
}
@media (min-width: 768px) and (max-width: 1600px) {
  .health-info-dropdown {
    font-size: smaller;
    height:fit-content;
  }
}
@media (min-width: 1601px) {
  .health-info-dropdown {
    font-size: small;
    height:fit-content;
  }
}

/* Alerts text & icons */
.health-info-alerts-text {
  color: #C8C8C8;
  margin-left: 0.3rem;
}
@media (max-width: 600px) {
  .health-info-alerts-text {
    font-size: small;
    color: #C8C8C8;
  } 
}
@media (min-width: 601px) and (max-width: 1600px) {
  .health-info-alerts-text {
    font-size: smaller;
    color: #C8C8C8;
  }
}
@media (min-width: 1601px) {
  .health-info-alerts-text {
    font-size: medium;
    color: #C8C8C8;
  }
}


@media (max-width: 600px) {
  .health-info-alerts-icon {
    color: #C8C8C8;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    font-size: small;
  } 
}
@media (min-width: 601px) and (max-width: 1600px) {
  .health-info-alerts-icon {
    color: #C8C8C8;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    font-size: smaller;
  }
}
@media (min-width: 1601px) {
  .health-info-alerts-icon {
    color: #C8C8C8;
    margin-left: 0.5rem;
    margin-top: 0.1rem;
    font-size: medium;
    
  }
}

.alert-table-container {
  position: relative;
  display: flex;
}

/* Bell icon badge */
.bell-icon-badge-alert-table {
  margin-top: -0.3rem;
  margin-left: -0.1rem;
  width: 1rem; 
  height: 1rem;
  background-color: red;
  color: white;
  font-size: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

@media (max-width: 600px) {
  .bell-icon-badge-alert-table {
    margin-top: -0.3rem;
    margin-left: -0.1rem;
    width: 0.9rem; 
    height: 0.9rem;
    background-color: red;
    color: white;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
}

/* Server / Camera Activity label */
.activity-label { 
  padding: .6rem;
  margin-top: 2rem;   
  font-size: large;
  color: #fafafa;
}
@media (max-width: 600px) {
  .activity-label {    
    padding: .6rem;
    margin-top: 2rem;   
    font-size: medium;
  }
}
@media (min-width: 601px) and (max-width: 1600px) {
  .activity-label {    
    padding: .6rem;
    margin-top: 2rem;   
    font-size: large;
  }
}
@media (min-width: 1601px) {
  .activity-label {    
    padding: .6rem;
    margin-top: 2rem;   
    font-size: larger;
  }
}

/* Alert table Details btn  */
.alert-table-details-btn {
  margin-right: .5em;
  font-size: small;
}
@media (max-width: 600px) {
  .alert-table-details-btn {
    margin-right: .5em;
    font-size: x-small;
  }
}

.unackalerts-modal-title {
  color: white;
  font-size: .68em;
  margin-left: 1em;
}
@media (max-width: 600px) {
  .unackalerts-modal-title {
    color: white;
    font-size: small;
    margin-left: 1em;
  }
}

/* health info main container */
.health-info-container {
  margin-left: 15%;
  margin-top: 4%;
  overflow: auto;
}
@media (max-width: 600px) {
  .health-info-container {
    margin-left: 0%;   
   
  }
}


@media (max-width: 680px) {
  canvas {
    height: 290px!important;
    width: 290px!important; 
  }
}

/* SAMURAI logo when showing loading icon on health info page while refreshing  */
.health-info-loading-img {
  justify-content: center;
  align-items: center;
  display: grid;
  margin-left: 15%;
  margin-top: 12%;
}
@media (max-width: 600px) {
  .health-info-loading-img {
    justify-content: center;
    align-items: center;
    display: grid;
    margin-left: 0%;
    margin-top: 20%;
    height: 100vh;
  }
}

.health-info-loading-img-ring-loader {
 width: 10%!important;
}

.health-info-loading-img-footer {
  margin-top: 3rem;
}
@media (max-width: 600px) {
  .health-info-loading-img-footer {
    margin-top: 10rem;
  }
}
@media (min-width: 601px) and (max-width: 1600) {
  .health-info-loading-img-footer {
    margin-top: 0rem;
  }
}
@media (min-width: 1601px) {
  .health-info-loading-img-footer {
    margin-top: 10rem;
  }
}

/*  Failover icon */
#failover-icon {  
  width: 2.5rem;
  margin-left: 0.4rem;
}

.map-sitename-label {
  font-weight: bold;
}
.map-sitename-value {
  font-size: 0.8rem;
}

.modal-header .btn-close  {
  color: red!important
}

.health-info-footer-container {
  padding: 3.7em;
  margin-left: -15%;
  margin-top: -2em;
}

@media (max-width: 600px) {
  .health-info-footer-container {
    margin-left: 0;
    margin-top: -2em;
  }
}
@media (min-width: 600px) and (max-width: 1600px) {
  .health-info-footer-container {
    margin-top: -4em;
  }
}

.loading-select-business {
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .loading-select-business {
    height: 10rem;    
  }
}
@media (min-width: 600px) and (max-width: 1600px) {
  .loading-select-business {
    height: 25rem;    
  }
}
@media (min-width: 1601px) {
  .loading-select-business {
    height: 33rem
  }
}





