/* GradedBar.css */

.camera-graded-bar {
    width: 6em;
    height: 1.4em;
    background: transparent;
    border-radius: 0.8em;
    overflow: hidden;
    position: relative;
    margin-left: 0.4em;
  }
  
  .camera-graded-bar-inner {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  