.login-sidebar-container {
    margin-top: -2.6em;
}

#login-container { 
    margin-left: 15%;  
    display:flex;
    justify-content:center;
    height: auto;
    color: white;
    margin-top: 4em;
    height: auto;
}
@media(max-width: 786px) {
    #login-container { 
        margin-left: 0%;       
        padding: 0 1.4rem; 
        margin-top: 2em;
        
    } 
}
@media(min-width: 786px) and (max-width: 1600px) {
    #login-container {              
        padding: 0 1.4rem; 
        margin-top: 2em;
          
    } 
}
@media(min-width: 1601px) {
    #login-container {   
        padding: 0 1.4rem; 
            
    } 
}


#input-card {
    width: 25rem;
    height: 50%;
    background-color: #2A4E7B;
    margin-top: 4em;
    border-radius: 0.6em;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.4);
}
@media(max-width: 786px) {
    #input-card {
        width: 24em;
        height: 70%;
        margin-top: 2em;
        border-radius: 0.6em;
    }
}
@media(min-width: 787px) and (max-width: 1600px) {
    #input-card {
        width: 28em;
        height: auto;
        margin-top: 2em;
        border-radius: 0.6em;
    }
}
@media(min-width: 1601px) {
    #input-card {
        width: 30em;
        height: auto;
        margin-top: 2em;
        border-radius: 0.6em;
    }
}



.card-title {
    font-size: 1.1rem;
    padding: 0.5em;
}
.input-box {
    font-size:medium;
    height: 3em;
    width: 100%;
    margin-bottom: 1em;
    border-radius: .6em;
    border-width: .4px;
    padding: 1em;
}
@media(max-width: 786px) {
    .input-box {
        font-size:small;
        height: 2.4rem;
        width: 100%;
        margin-bottom: 1em;
        border-radius: .4em;
        border-width: .4px;
        padding: 1em;
    }
}

.signin-btn {
    width: 100%;
    border-radius: 0.3em;
    font-size: 1em;
    padding: 0.5em;
    background-color: #F49523;
    border-width: 0;
}
.signin-btn:hover {
    background-color: #8c5099;
    color: #fafafa;
}
@media(max-width: 786px) {
    .signin-btn {
        font-size: 0.9em;
        padding: 0.4em;
    }
}

.modal-content {   
    border: none;
    background-color: transparent;
    overflow: hidden; 
}
.modal-body {
    border-bottom-left-radius: 1em;
    border-bottom-right-radius: 1em;
}

.modal-header {
    background: #384670;
    color: #fafafa;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  }

.modal-color {
background-image: url('../images/BackgroundImageLogout.png');
border-bottom-left-radius: 15px; /* Match the modal-content border-radius */
border-bottom-right-radius: 15px; /* Match the modal-content border-radius */
}
  
.signup-modal-header-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.signup-submit-btn  {
    background: linear-gradient(to right, #ff90c6, #594de5);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);   
    border: none;
    color: #fafafa;
    width: 5em;
    font-size: medium;
    border-radius: 0.4em;
    padding: 0.4em 0;
    width: 100%;
}
.signup-submit-btn:hover  {
    background: linear-gradient(to right, #594de5, #ff90c6);
}


.signup-btn {
    border: none;
    text-decoration: none;
    font-size: 1em;
    color: #F49523;
    border-width: 0;
    margin-left: 0.5em;
    background-color: transparent;
}
.signup-btn:hover {
    font-weight: 600;
}

.signin-cancel-btn {
    background-color: transparent;
    border-width: 0;
    text-decoration: none;
    color: lightgray;
    font-size: 1em;
    text-align:end;
    margin-right: 1em;
    margin-bottom: 1em;
    font-weight: 400;
}
.signin-cancel-btn:hover {
    color: #F49523;
}
@media(max-width: 786px) {
    .signin-cancel-btn {
        font-size: 0.8em;
    }
}


#signin-mfa-text {
    font-size: 0.8em;
    color: lightGray;
    margin: 1em 0;
}
@media(max-width: 786px) {
    #signin-mfa-text {
        font-size: 0.65rem;
        color: lightGray;
        margin: 1em 0;
    }
}

#input-box-mfa {
    font-size:medium;
    height: 1rem;
    margin-bottom: 1em;
    border-radius: .4em;
    border-width: 0px;
    border-color: #212529;
    padding: 0.95em;
    width: 100%;
}
@media(max-width: 786px) {
    #input-box-mfa {
        font-size:small;
        height: 1rem;
        margin-bottom: 1em;
        border-radius: .4em;
        border-width: .4px;
        border-color: #212529;
        padding: 1em;
        width: 100%;
    } 
}

.signup-icon {
   height: 1.8em;
   color: #e9426C;
   padding: .5em;
   margin-top: -2em;
}

#input-box-signup {
    font-size:medium;
    height: 2.4rem;
    width: 100%;
    margin-bottom: 1em;
    border-radius: .6em;
    border-width: .4px;
    border-color: #212529;
    padding: 1em;   
}
@media(max-width: 786px) {
    #input-box-signup {
        font-size:small;
        height: 1.8rem;
        width: 100%;
        margin-bottom: 1em;
        border-radius: .6em;
        border-width: .4px;
        border-color: #212529;
        padding: 1em;   
    }
}

.signin-submit-code-btn {
    width: 100%;
    border-radius: 0.3em;
    font-size: 1em;
    padding: 0.5em;
    background-color: #F49523;
    border-width: 0;
}
.signin-submit-code-btn:hover {
    background-color: #3B92B3;
}
@media(max-width: 786px) {
    .signin-submit-code-btn {
        font-size: 0.9em;
        padding: 0.4em;
    }
}

.signup-confirm-code-btn {
    border-radius: 0.3em;
    font-size: 1em;
    padding: 0.5em;
    background-color: #384670;
    color: lightgray;
    border-width: 0;
}
.signup-confirm-code-btn:hover {
    background-color: #335f96;
}
@media (max-width: 600px) {
    .signup-confirm-code-btn {
        font-size: 0.9em;
    }   
}

#input-box-signup-phonenumber {
    font-size:medium;
    height: 2.8rem;
    width: 100%;
    border-radius: .6em;
    border-width: .4px;
    border-color: #212529;
    padding: 1em;
}
@media(max-width: 786px) {
    #input-box-signup-phonenumber {
        font-size:small;
        height: 2.4rem;
        width: 100%;
        border-radius: .6em;
        border-width: .4px;
        border-color: #212529;
        padding: 1em;
    }
}
#signup-bottom-text {
    font-size: 0.7rem;
    color: darkgray;
    font-weight: 500;
}

#signup-phonenumber-label {
    font-size: 0.9rem;
    font-weight: 500;
}
@media(max-width: 786px) {
    #signup-phonenumber-label {
        font-size: 0.68rem;        
    }
}

.input-box-forgotpasssword {
    font-size:medium;
    height: 1rem;  
    border-radius: .4em;
    border-width: .3px;
    border-color: #212529;
    padding: 1em;
}
@media(max-width: 786px) {
    .input-box-forgotpasssword {
        font-size:small;
        padding: 0.95em;
    }
}

#input-box-signup-confirmation {
    font-size:medium;    
    width: 100%;    
    border-radius: .6em;
    border-width: .4px;
    border-color: #2A4E7B;
    padding: 0.5em;
    margin-bottom: 0.4rem;
}
@media(max-width: 786px) {
    #input-box-signup-confirmation {
        padding: 0.3em;
    }
}

#input-box-signup-confirmation-text {
    font-size: 0.9em;
    margin-bottom: 0.5rem;
    color: #191919;
}
@media(max-width: 786px) {
    #input-box-signup-confirmation-text {
        font-size: 0.777em;
        margin-bottom: 0.5rem;
        color: #191919;
    }  
}

#input-btn {
    margin-top: 0.5em;
    margin-bottom: 1em;
    margin-left: 1rem;
}
@media(max-width: 786px) {    
#input-btn {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    font-size: small;
}
}


#input-card-signup {
    border-width: 0;
}

.forgot-password-container {
    border-width: 0;
}



#login-icon {
    height: 2em;
    
}

#bottom-text {
    font-size: .9rem;
    margin-top: 0.5rem;
}
@media(max-width: 786px) {    
    #bottom-text {
        font-size: 0.75rem;
    }
}

#qrcode-text {
    font-size: .9rem;
    margin-top: 0.5rem;
    color: lightgray;
}
@media(max-width: 786px) {    
    #qrcode-text {
        font-size: 0.75rem;
    }
}
.qrcode-text-code-div {
    margin-bottom: 0.3em;
}

#qrcode-text-code {
    font-size: .66rem;
    margin-top: 0.5rem;
    color: rgb(163, 163, 163);
}
@media(max-width: 786px) {    
    #qrcode-text-code {
        font-size: 0.6rem;
    }
}

#bottom-btn {
    font-size: 0.9rem;    
}
@media(max-width: 786px) {    
    #bottom-btn {
        font-size: 0.75rem;    
    }
}

#submit-mfa-btn {
    margin-left: 2.8rem;
    font-size: small;
    padding: 0.35rem 0.5rem;
    margin-top: -0.25rem;  
}

#signup-modal-header {
    font-size: large;

}
@media(max-width: 786px) {
    #signup-modal-header {
        font-size: medium;    
    }
}

.react-toast-notifications__container.css-nvf14r-ToastContainer {
  z-index: 9999!important; /* Adjust the value as needed */
}

.forgot-password-text {
    font-size: 0.9em;
    margin-top: -1em;
    margin-bottom: 0.5em;
}
@media(max-width: 786px) {
    .forgot-password-text  {
        font-size: 0.72em;    
    }
}

.forgot-password-request-code-btn {
    border-radius: 0.3em;
    font-size: 1em;
    padding: 0.5em 0.7em;
    background-color: #bc358b;
    color: #fafafa;
    border-width: 0;   
}

.verification-code-label {
    margin-bottom: 1rem;
    margin-top: -0.5rem;
    font-size: small;
    font-weight: 500;
}
@media(max-width: 786px) {
    .verification-code-label {
       font-size: 0.8em;
    }
}

.forgot-password-submit-btn {
    border-radius: 0.3em;
    font-size: 1em;
    padding: 0.5em;
    background-color: #2A4E7B;
    color: lightgray;
    border-width: 0;
}
.forgot-password-submit-btn:hover {
    background-color: #335f96;
}
@media(max-width: 786px) {
    
.forgot-password-submit-btn {
   font-size: 0.9em;
}
}

@media(max-width: 786px) {
    .signin-footer-container {
        margin-top: 5em;
    }
}
@media(min-width: 786px) and (max-width: 1600px) {
    .signin-footer-container {
        margin-top: 0em;
    }
}
@media(min-width: 1601px) {
    .signin-footer-container {
        margin-top: 10em;
    }
}





