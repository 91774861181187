.admin-container {
  margin-top: 3em;
  margin-left: 15%;
  display: grid;
  padding: 0 22em;
}
@media (max-width: 600px) {
  .admin-container {
    padding: 0 1em;
    margin-top: 1em;
    margin-left: 0; 
}}
@media (min-width: 600px) and (max-width: 1600px) {
  .admin-container {  
    margin-left: 15%;   
    padding: 0 22em;
    margin-top: 6em;
}}
@media (min-width: 1601px) {
  .admin-container {
    margin-top: 8.5em;
    margin-left: 15%;    
    padding: 0 22em;   
}}


.admin-header {
  height: 3.3rem;
  background-color: #2A4E7B;
  width: auto;
  border-radius: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

@media(max-width: 600px) {
  .admin-header {
    height: 2.5rem;       
    border-radius: 0.6rem;
    border-radius: 0.4rem;    
  }
}
.admin-header-text {
 color: white;
 font-size: medium;
}
@media(max-width: 600px) {    
.admin-header-text {   
  font-size: small;
}
}


.select-admin-dropdown {
  height: fit-content;
  font-size: 0.84em;
  width: 100%;
}
@media (max-width: 600px) {
  .select-admin-dropdown {
    font-size: 0.65em;
  }
}

.select-user-dropdown-btn-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select-admin-user-dropdown {
  height: fit-content;
  font-size: 0.84em;
  width: 100%;
}
@media (max-width: 600px) {
  .select-admin-user-dropdown {
    font-size: 0.65em;
  }
}

#new-user-input {
   width: 100%;
   border-radius: 0.3em;
   margin-top: 0.3em;
   border-width: 0;
   padding: 0.2em;
}
@media (max-width: 600px) {
  #new-user-input {
    font-size: 0.8em;
  }
}
#new-user-input::placeholder {
  font-size: 0.9em;
}
@media (max-width: 600px) {
  #new-user-input::placeholder {
    font-size: 0.85em;
  }
}

.new-user-register-btn {   
   margin-top: 1em;
   background: linear-gradient(to right, #ff90c6, #594de5);
   border-radius: 0.4em;
   padding: 0.4em;
   color: #fafafa;
   font-size: 1.1em;
   border: none;
}
@media (max-width: 600px) {
  .new-user-register-btn {   
    border-radius: 0.4em;
    padding: 0.4em;
    color: #fafafa;
    font-size: 0.85em;
 }
}

.new-user-register-btn:hover {
  background: linear-gradient(to right, #594de5, #ff90c6);
  box-shadow: 0 4px 8px rgba(188, 53, 139, 0.4);
}
 
.user-type-label {
    color: lightgray;
    margin-right: 0.3em;
}

.select-user-container {
    margin-top: 1em;
    width: 100%;
}


.site-table-label {
    margin-top: 1em;
    margin-bottom: 0.5em;   
    color: lightgray;
    font-size: 0.85em;
}
@media (max-width: 600px) {
  .site-table-label {
    font-size: 0.8em;
  }
}

.select-label {  
  color: lightgray;
  margin-right: 0.3em;
  margin-bottom: 0.5em;
  font-size: 0.85em;
}
@media (max-width: 600px) {
  .select-label {
    font-size: 0.8em;
  }
}
.select-user-label {
  color: lightgray;
  margin-right: 0.3em;
  font-size: 0.85em;
}
@media (max-width: 600px) {
  .select-user-label {
    font-size: 0.8em;
  }
}

.delete-user-btn {
  background-color: transparent;
  margin-right: 0.3em;
  margin-left: 1em;
  border-width: 0;
  color: whitesmoke;
  border-radius: 0.2em;
  padding: 0.2em 0.5em;
}
.delete-icon {
  height: 1.5em;
  margin-bottom: 0.5em;
  opacity: 0.78;
}
.delete-icon:hover {
  opacity: 0.9;
}
#delete-user-modal-header {
  font-size: 0.85em;
}

.table-container-admin {
    overflow-x: auto; /* Enable horizontal scrolling when needed */
    max-height: 30vh; /* Set a maximum height for the table */ 
    background-color: #071d2c;
    border-radius: 0.5em;
  }
  @media (min-width: 601px) and (max-width: 1600px) {
    .table-container-admin {
      max-height: 20vh;
    }
  }
  @media (min-width: 1601px) {
    .table-container-admin {
      max-height: 25vh;
    }
  }
  /* Change scrollbar color */
  .table-container-admin::-webkit-scrollbar {
    width: 13px; /* Width of the scrollbar */
  }
  .table-container-admin::-webkit-scrollbar-track {
    background: #000; /* Color of the track */
  }
  .table-container-admin::-webkit-scrollbar-thumb {
    background: #334066; /* Color of the thumb */
  }

  .admin-table {
    font-size: 0.68em;
  }
  @media (max-width: 600px) {
    .admin-table {
      font-size: 0.6em;
    }
  }

  .no-site-label {
    margin-top: 1em;
    margin-bottom: 0.5em;
    margin-left: 0.7em;
  }

  .admin-footer {
    margin-top: 2em;
  }
  @media (max-width: 600px) {
    .admin-footer {
      padding: 4em 0;
    }
  }
  @media (min-width: 601px) and (max-width: 1600px) {
    .admin-footer {
      margin-top: 2em;
    }
  }
  @media (min-width: 1601px) {
    .admin-footer {
      margin-top: 7em;
    }
  }

 
  