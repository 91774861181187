.report-container {
    margin-left: 15%;  
    display: grid;
    justify-content: center;
    width:auto;
    margin-top: 6rem;
    height: auto;
}
@media (max-width: 600px) {
    .report-container {
        margin-left: 0%;        
        justify-content: center;
        margin-top: 0rem;
    }
}
@media (min-width: 601px) and (max-width: 1600px) {
  .report-container {
    margin-left: 15%;  
    display: grid;
    justify-content: center;
    width:auto;
    margin-top: 3.4rem;
}
}


.report-header {
    height: 3.3rem;
    background-color: #36446d;
    width: auto;
    border-radius: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
@media(max-width: 600px) {
    .report-header {
        height: 2.6rem;       
        border-radius: 0.6rem;
        border-radius: 0.4rem; 
        margin-bottom: 1rem;
        margin-top: 1rem;   
    }
}


.report-header-text {
   color: white;
   font-size: large;
}
@media(max-width: 600px) {    
.report-header-text {   
    font-size: medium;
 }
}

.report-form-container {
    background-color: #36446d;
    padding: 2rem 4rem;
    border-radius: 1rem;
    color: #313030;
    font-weight: 600;
    font-size: medium;
    margin-top: 5%;
    margin-bottom: 18%;
    opacity: 0.8; 
}
@media (max-width: 600px) {    
    .report-form-container {
        background-color: #36446d;
        padding: 2rem 3rem;
        border-radius: 0.7rem;
        color: #313030;
        font-weight: 600;
        font-size: small;
        margin-top: 5%;
        margin-bottom: 50%;
        margin-left: 0%;
        padding: 2rem 2rem;
    }
}
.form-btn-submit {
  background-color: #F49523;
    border: none;
    color: black;
    width: 5em;
    font-size: 1em;
    border-radius: 0.3em;
    padding: 0.4em 0;
}
.form-btn-submit:hover {
  background-color: #8E338C;
  color: #fafafa;
}
@media (max-width: 600px) {
    .form-btn-submit {
        font-size: 0.9em;
    }
}

.form-btn-cancel {
  background-color: transparent;
  border: none;
  color: #fafafa;
  width: 5em;
  font-size: 0.95em;
  border-radius: 0.3em;
  opacity: 0.95;
  text-align: end;
}
.form-btn-cancel:hover {
  color: #F49523;
  font-weight: 600;
}
@media (max-width: 600px) {
  .form-btn-cancel {
      font-size: smaller;
  }
}

.download-pdf-btn {
    margin-top: 2rem;
    width: 100%;
    font-size: medium;
}
@media (max-width: 600px) {
    .download-pdf-btn {
        margin-top: 2rem;
        width: 100%;
        font-size: small;
    }
}


/* selectors container */
.selectors-container-report {
    display: grid;
    margin-top: 0.3rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  @media (max-width: 600px) {
    .selectors-container-report {
      display: grid;
      margin-top: 0.3rem;
      margin-bottom: 2rem;
      width: fit-content;
    }
  }

  /* select labels  */

.report-info-select-label {
    padding: .3rem;
    font-size: .75rem;
    margin-left: 9rem;
  } 
  
  @media (max-width: 600px) {
    .report-info-select-label {
      padding: .3rem;
      font-size: .75rem;
      margin-left: 0rem;
      font-size: smaller;   
    } 
  }
  @media (min-width: 601px) and (max-width: 767px) {
    .report-info-select-label {
      padding: .3rem;
      font-size: smaller;
      margin-left: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    .report-info-select-label {
      padding: .3rem;
      font-size: medium;
      margin-left: 0;
    }
  }
  @media (min-width: 1601px) {
    .report-info-select-label {
      padding: .3rem;
      font-size: medium;
      margin-left: 0;
    }
  }

  /* dropdowns */
.report-info-dropdown {
    font-size: small;
    height:fit-content;
    margin-bottom: 1em;
  }
  @media (max-width: 600px) {
    .report-info-dropdown {
      font-size: xx-small;
      height:fit-content;
      margin-left: 0rem;
      width: 21rem;
    } 
  }
  @media (min-width: 601px) and (max-width: 767px) {
    .report-info-dropdown {
      font-size: x-small;
      height:fit-content;
      width: 21rem;
    }
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    .report-info-dropdown {
      font-size: smaller;
      height:fit-content;
      width: 21rem;
    }
  }
  @media (min-width: 1601px) {
    .report-info-dropdown {
        font-size: medium;
        height:fit-content;
        width: 21rem;
    }
  }

  .report-loading-container {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
  }
  @media (max-width: 600px) {
    .report-loading-container {
      margin-left: 0%;
      margin-top: 4em;
    }
  }

  .report-info-loading-img {
    margin-top: 2em;
    height: 60vh;
  }
  @media (max-width: 600px) {
    .report-info-loading-img {
      margin-top: 1em;
      height: 20vh;
    }
  }

  .form-label {
    color: #fafafa;
    font-weight: 500;
    font-size: smaller;
  }


  @media (min-width: 1601px) {
    .footer-container-report {
      margin-top: 6em;
    }
  }
  @media (min-width: 1601px) and (max-width: 1600px){
    .footer-container-report {
      margin-top: 1em;
    }
  }
  @media (min-width: 601px) and (max-width: 1600px) {
    .footer-container-report {
      margin-top: -4em;
    }
  }
  @media (max-width: 600px) {
    .footer-container-report {
      margin-top: 0em;
    }
  }

@media (max-width: 600px) {
  .react-datepicker__input-container .form-control {
    font-size: smaller;
    width: 170%;
  }
}

.reports-sidebar-small {
  margin-top: -10em;
}
