ul.sidebar-ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 15%;
    height: 100%;
    overflow:hidden;
    position: fixed;
    margin-top: -1.6em;
    font-size: 0.94em;
    background-color: #262538;
    opacity: 0.9;
}
@media (max-width: 600px) {    
    ul.sidebar-ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 0%;
        height: 100%;
        overflow:hidden;
        position: fixed;
    }
    }
@media (min-width: 600px) and (max-width: 1600px) {    
    ul.sidebar-ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 15%;
        height: 100%;
        overflow:hidden;
        position: fixed;
        margin-top: -2em;
        /* font-size: 0.94em; */
        font-size: 0.83em;
    }
}
@media (min-width: 1601px) {    
    ul.sidebar-ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 15%;
        height: 100%;
        overflow:hidden;
        position: fixed;
        margin-top: -1.7em;
        font-size: 0.94em;
    }
}

.sidebar-ul-img-container {
    display: flex;
    justify-content: center;
}

.sidebar-ul-img {
    margin-bottom: 5%;
    width: 100%;
    padding: 0;
}

.sidelink-container {
    margin-top: -1.7em;
    /* margin-bottom: 1em; */
    margin-bottom: 0.5em;

}

ul.sidebar-ul li a {
    display: block;
    text-decoration: none;
    color: rgb(236, 226, 226);
    /* padding: 6px 16px; */
    padding: 4.5px 16px;
    border-radius: 10px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1.3%;
    font-size: 0.85em;
}
@media (min-width: 601px) and (max-width: 1600px) {
    ul.sidebar-ul li a {
        font-size: 0.8em;
    } 
}
@media (min-width: 1601px) {
    ul.sidebar-ul li a {
        font-size: 0.9em;
    } 
}

.sidelink-container li.selected a {
    background-color: #4D529E!important;   
}

ul.sidebar-ul li a:hover {
    background-color: #F49523;
    color:white;
}

.sidebar-ul-card-li {
    padding: 0 1.5em;
    margin: 0.3em 0;

    /* margin: 0.5em 0; */
}

.sidebar-ul-card-container {
    margin-top: 0.4em;
}

.sidebar-ul-card {
    width: auto;
    margin-top: 1%;
    border-radius: 0.8em;
    background-color: #2e2b46;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
}
@media (min-width: 601px) and (max-width: 1600px) {
    .sidebar-ul-card {
        width: auto;
        border-radius: 0.7em;
        background-color: #2e2b46;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    }
}
.sidebar-ul-card:hover {
    box-shadow: 5px 5px 15px rgba(66, 59, 59, 0.9);
}

.sidebar-ul-card-body {
    padding: 0;
}

.card-text {
    padding: 0.4em;
    color: lightgray;
    font-size: xx-small;
}

p {
    margin-bottom: 0;
}


/* responsive layout */
@media (max-width: 600px) {
    .card-text {
        font-size: xx-small;
        color: lightgray;
    } 
}

@media (min-width: 601px) and (max-width: 1600px) {
    .card-text {
        font-size: 0.57em;
        color: lightgray;
    }
}

@media (min-width: 1601px) {
    .card-text {
        font-size: x-small;
        color: lightgray;
    }
}

.card-title {
    font-size: 0.9rem;
    color: lightgray;
}
@media (max-width: 600px) {
    .card-title {
        font-size: xx-small;
    } 
}

@media (min-width: 601px) and (max-width: 1600px) {
    .card-title {
        font-size: x-small;
    }
}

@media (min-width: 1601px) {
    .card-title {
        font-size: small;
    }
}

/*  card action button  */
.sidebar-ul-card-btn-container{
    background-color: transparent!important;
    margin: 0!important;
    padding: 0!important;
}

.card-btn {
    color: #F49523;
    font-size: x-small;
    opacity: 0.9;
    background: none;
    border: none;
    margin: 0 0 0.3em 0.3em;
}
a:hover .card-btn {
    color:#F49523;
    opacity: 1;
}

@media (max-width: 600px) {
    .card-btn {
        font-size: xx-small;
    } 
}

@media (min-width: 601px) and (max-width: 1600px) {
    .card-btn {
        font-size: 0.6em;
    }
}

@media (min-width: 1601px) {
    .card-btn {
        font-size: 0.65em;
    }
}

/* small screen */
.sidebar-smallscreen-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */  
    gap: 10px; /* Space between elements */
    margin-top: 5em;
    padding: 1em;
}
 
 
.sidebar-smallscreen-card {  
    width: 85%; /* Reduce width to 70% */
    background-color: #2e2b46;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
    padding: 10px;
 
}