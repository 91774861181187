body, html {
  background-image: url('./images/BackgroundImage.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;  
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  height: 100vh;
  background-attachment: fixed;
}


