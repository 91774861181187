/* license.css */

.license-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    text-align: left;
    color: black;
 
  }

  table td {
    padding: 8px; /* Add padding for spacing */
    border: 1px solid #ddd;
    color: #ccc;
    font-size: 0.8rem;
  }
  @media (max-width: 600px) {
    table td {
     font-size: 0.5rem;
    }
  }

  .license-container {
    display: grid;   
    margin: 1em;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
  }
  
  .license-div {
    text-align: center;
    justify-content: space-around;
  }

   /* Apply styles to the table container */
 
   .table-container-license {
    overflow-x: auto; /* Enable horizontal scrolling when needed */
    max-height: 33em; /* Set a maximum height for the table */ 
    background-color: #071d2c;
  }
  .table-container-license th {
    background-color: #071d2c;
  }
  /* Change scrollbar color */
  .table-container-license::-webkit-scrollbar {
    width: 0.9em; /* Width of the scrollbar */
  }
  
  .table-container-license::-webkit-scrollbar-thumb {
    background: #334066; /* Color of the thumb */    
  }

  .table-container-license th {
    background-color: #071d2c;
  }


.license-actions {
  display: flex;
  justify-content: space-evenly;
  
}

#license-action-btn {
  font-size: 0.7rem;
  margin-right: 0.5em;
}
@media (max-width: 600px) {
  #license-action-btn {
    font-size: 0.5rem;
    margin-right: 0.5em;
  }
}

.license_users_select {
  width: auto;
  padding: 6px;
  font-size: smaller;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  background-color: #212529;
  color: white;
}

.license_users_select option {
  background-color: #212529;
  color: white;
}

.license_users_select option:hover {
  background-color: #F49523!important;
  color: white;
}


.pagination-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.pagination-next-btn {
   margin-left: 1rem;
}
.info-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #3498db; /* You can change the color */
  color: #fff;
  font-size: 0/7rem;
  text-align: center;
  line-height: 1rem;
  margin-right: 0.3rem;
  cursor: pointer;
}

.info-icon::before {
  content: 'i';
  font-weight: bold;
  font-style: normal;
}

.info-icon::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.info-icon:hover::after {
  opacity: 1;
}

.create-license-modal-header-text {
  color: #ccc;
  font-size: 0.85em;
  margin-left: 0.5em;
}

.form-label {
  color: #ccc!important;
  font-size: 0.95em;
}

.create-license-form-submit-btn {
  background: linear-gradient(to right, #ff90c6, #594de5);
  border-radius: 0.4em;
  padding: 0.4em;
  color: #fafafa;
  font-size: 1.1em;
  border: none;
  width: 100%;
}
.create-license-form-submit-btn:hover { 
  background: linear-gradient(to right, #594de5, #ff90c6);  
}

.create-license-form-clear-btn-container {
  display: flex;
  justify-content: flex-end;
}
.create-license-form-clear-btn {
  margin-top: 1em;
  background: none;
  border: none;
  color: #F49523;
}
.create-license-form-clear-btn:hover {
  color: #ccc;
}

.refresh-icon-container {
  border: none;
  background-color: green;
  color: #F49523;
  font-size: 1.1em;
  border-radius: 50%;
}
.refresh-icon-container:hover {
  color: #fafafa;
}

.total-license {
  font-size: 0.9em;
  color: #ccc;
  padding: 0.5em 0;
}

.license-select-container {
  display: flex;
  justify-content: center;
}
@media (max-width: 600px) {
  .license-select-container {
    display: grid;
  }
}

.license-business-select-container {
  padding: 0 1em;
}
@media (max-width: 600px) {
  .license-business-select-container {
    padding: 0;
  }
}

.license-select-label {
  font-size: 0.9em;
  color: #ccc;
}
@media (max-width: 600px) {
  .license-select-label {
    font-size: 0.8em;
    margin-top: 0.5em;
  }
}

.license-info-dropdown {
  font-size: 0.8em;
  width: 14rem;
}
@media (max-width: 600px) {
  .license-info-dropdown {
    font-size: 0.65em;
  }
}

.error-message-container {
  display: flex;
  justify-content: center;
  margin-top: 2em;

  height: 70vh;
}
@media (max-width: 600px) {
  .error-message-container {
    height: 70vh;
  }
}
@media (min-width: 601px) and (max-width: 1600px) {
  .error-message-container {
    height: 60vh;
  }
}
@media (min-width: 1601px) {
  .error-message-container {
    height: 70vh;
  }
}

.error-message-text {
  color: #F49523;
  font-size: 1em;
}
@media (max-width: 600px) {
  .error-message-text {
    font-size: 0.8em;
  }
}




.delete-modal-title {
  color: #ccc;
  font-size: 0.7em;
}
@media (max-width: 600px) {
  .delete-modal-title {
    font-size: 0.65em;
  }
}

.delete-modal-body {
 background-color: #334066;
 color: #ccc;
 font-size: 0.85em;

}
@media (max-width: 600px) {
  .delete-modal-body {
    font-size: 0.7em;
 
  }
}

.custom-switch {
  color: #ccc!important;
  font-size: 0.95em;
}

.create-licnese-form-recipient-toggle-label-container {
  display: flex;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.table-container-license {
  position: relative;
}

.license-create-form-footer-container {
  font-size: 0.85em;
  margin-top: -1em;
}
.license-create-form-footer-text {
  color: #ccc;
}
.license-create-form-footer-email {
  color: #F49523;
}
.search-input-container {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
 
}
.search-input { 
  margin-right: 1em;
  width: 15rem;
  font-size: 0.8rem;
  padding: 0.2em;
  margin-bottom: 1em;
  background-color: #071d2c;
  color: #fafafa;
  border-width: 1px;
}

.no-license-found {
  color: #ccc;
  font-size: 0.9em; 
}
.search-input-clear {
  background: none;
  border: none;
  color: #F49523;
  margin-top: -1em;
}
