/* Notification Panel styles */
.notification-panel {
    position: absolute;
    margin-top: 20px;
    right: 0;
    width: 350px;
    background: linear-gradient(to right, rgba(157, 155, 166, 1), rgba(34, 34, 51, 1));
    border-radius: 8px;
    z-index: 9999;
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
    color: rgb(236, 226, 226);
    opacity: 1;
  }
  @media (min-width: 600px) and (max-width: 1680px) {
    .notification-panel {
      width: 300px;
    }
  }

  .notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd; 
    font-size: 1rem;   
  }
  .notification-header-text {
    font-size: 1rem;
  }
  @media (min-width: 600px) and (max-width: 1680px) {
    .notification-header-text {
      font-size: 0.85rem;
    }
  }
  @media (max-width: 600px) {
    .notification-header-text {
      font-size: 0.8rem;
    }
  }

  .notification-panel-small {
      position: absolute;
      margin-top: 50px;
      right: 0;
      width: 300px;
      background: linear-gradient(to right, rgba(157, 155, 166, 1), rgba(34, 34, 51, 1));
      border-radius: 8px;
      z-index: 9999;
      padding: 10px;
      overflow-y: auto;
      color: rgb(236, 226, 226);
      font-size: .75rem;
  }
  @media (max-width: 600px) {
    .notification-panel-small {
      margin-top: 70px;
    }
  }

  .notification-header h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .notification-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .notification-item {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
  }

  .notification-item p {
    margin: 0;
    font-size: 0.9rem;    
  }
  @media (min-width: 600px) and (max-width: 1680px) {
    .notification-item p {
      font-size: 0.76rem;
    }
  }
  @media (max-width: 600px) {
    .notification-item p {
      font-size: 0.7rem;
    }
  }
  
  .notification-item small {
    color: #888;
    font-size: 12px;
  }

  /* Hover effect on notification items */
.notification-item:hover {
    background-color: #73737366;
  }

  /* Click here link */
.click-here {
    background: none;
    border: none;
    /* color: #0000EE; */
    /* color: #F89532; */
}

.click-here:hover {
    text-decoration: underline;
}

/* Ronin icon */
.ronin-icon-small {
  width: 40px;
  height: auto;
}
@media (min-width: 600px) and (max-width: 1680px) {
  .ronin-icon-small {
    width: 35px;
  }
}
@media (max-width: 600px) {
  .ronin-icon-small {
    width: 30px;
  }
}

.feature-release-date {
  /* float: right; */
  font-size: 11px;
}

.home-bottom-modal-title-skip-icon {  
  font-size: 0.9em;
  padding: 0.4em;
 }

.instruction-modal .modal-header {
  padding: 0.5em;
}

@media (max-width: 600px) {
  .instruction-modal .modal-header {
      padding: 0.3em;
  }
}
 /* home-bottom-modal-title-skip-icon-small {
  font-size: 0.1em;
 } */



