.lifecycle-info-container {
    margin-left: 15%;
    padding: 3em 10em 0em 10em;
  }
  @media (max-width: 600px) {
    .lifecycle-info-container {
      margin-left: 0%;
      padding: 3em 1em 0em 1em;
      height: 100vh;     
    }
  }

@media (max-width: 600px) {
    .lifecycle-info-welcome-text {
        font-size: 0.9em;
        margin-top: 2em;
        padding: 0 0.2em;
    } 
  }
  @media (min-width: 601px) and (max-width: 1600px) {
    .lifecycle-info-welcome-text {
        font-size: large;
    }
  }
  @media (min-width: 1601px) {
    .lifecycle-info-welcome-text {
        font-size: larger;
    }
  }
  
  @media (max-width: 600px) {
    .lifecycle-info-welcome-time {
      font-size: x-small;
      color: lightGray;
      padding: 0 0.3em;
    } 
  }
  @media (min-width: 601px) and (max-width: 1600px) {
    .lifecycle-info-welcome-time {
      font-size: small;
      color: lightGray;
    }
  }
  @media (min-width: 1601px) {
    .lifecycle-info-welcome-time {
      font-size: smaller;
      color: lightGray;
    }
  }

  /* selectors container */
.selectors-container {
    display: flex;
    margin-top: 0.3rem;
    width: fit-content;
    justify-content: flex-start;
    margin-bottom: 1rem;
  }
  @media (max-width: 600px) {
    .selectors-container {
      display: grid;
      margin-top: 0.3rem;
      margin-bottom: 0.5rem;
      width: fit-content;
      font-size: 0.9em;
    }
  }

  .lifecycle-info-select-label {
    padding: .3rem;
    font-size: .9rem;
    margin-left: 0.5rem;
  } 
  
  @media (max-width: 600px) {
    .lifecycle-info-select-label {
      padding: .3rem;
      font-size: .75rem;
      margin-left: 0.5rem;
      font-size: 0.8em;
    } 
  }

  
  /* dropdowns */
  .lifecycle-info-dropdown {
    font-size: 0.6em;
    height:fit-content;
  }
  @media (max-width: 600px) {
    .lifecycle-info-dropdown {
      font-size: 0.55rem;
      height:fit-content;
      margin-left: 0.8rem;
      width: 33em;
    } 
  }
  @media (min-width: 601px) and (max-width: 767px) {
    .lifecycle-info-dropdown {
      font-size: 0.6rem;
      height:fit-content;
    }
  }
  @media (min-width: 768px) and (max-width: 1600px) {
    .lifecycle-info-dropdown {
      font-size: 0.65rem;
      height:fit-content;
    }
  }
  @media (min-width: 1601px) {
    .lifecycle-info-dropdown {
      font-size: 0.7rem;
      height:fit-content;
    }
  }

  .view-icon-btn {
    border-width: 0;
    background-color: transparent;
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-left: 1em;
  }

  .view-icon-btn::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -16%;
    left: 290%;
    transform: translateX(-50%);
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    z-index: 1;
    font-size: 0.9em;
}

.view-icon-btn:hover::after {
    opacity: 1;
    visibility: visible;
}

  .view-icon {
    height: 1.2em;
    opacity: 0.8;
  }
  .view-icon:hover {
    height: 1.2em;
    opacity: 1;
  }

  .cameras-modal-title {
    font-size: 0.85em;
    color: rgb(218, 213, 213);
    font-weight: 400;
  }
  @media(max-width: 600px) {
    .cameras-modal-title {
      font-size: 0.7em;
    }
  }
  .cameras-modal-title-servername {
    color: #F49523;
  }

  .toggle-swich-container {
    display: flex;
    margin-left: 1em;
    font-size: 0.8em;
    margin-top: 1em;
  }
  @media(max-width: 600px) {
    .toggle-swich-container {
      font-size: 0.5em;
      margin-top: 2em;
    }
  }
  .toggle-swich-expired-lifeExpectancy {
    margin-left: 1em;
  }

  .reset-btn {
    background: transparent;
    border-width: 0;
    opacity: 0.8;
  }
  .reset-btn:hover {
    opacity: 1;
  }

  
@media (max-width: 600px) {
  .table-wrapper {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile devices */
}
}

.table-container-lifecycle-server {
  overflow-x: auto; /* Enable horizontal scrolling when needed */
  max-height: 33em; /* Set a maximum height for the table */ 
  background-color: #071d2c;
  font-size: 0.8em;
}
@media (max-width: 600px) {
  .table-container-lifecycle-server {   
    max-height: 33em;  
    font-size: 0.6em;
  }
}
.table-container-lifecycle-server th{
  background-color: #071d2c;
}

@media (max-width: 600px) {
  .table-container-lifecycle-server {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px; /* Adjust as needed to ensure the table has a minimum width */
}
}

@media (max-width: 600px) {
  .table-container-lifecycle-server th, .table-container-lifecycle-server td {
    padding: 8px;
    text-align: left;
}
}

  .table-container-lifecycle-camera {
    overflow-x: auto; /* Enable horizontal scrolling when needed */
    max-height: 33em; /* Set a maximum height for the table */ 
    background-color: #202841;
  }
  @media (max-width: 600px) {
    .table-container-lifecycle-camera {
      overflow-x: auto; /* Enable horizontal scrolling when needed */
      max-height: 20em; /* Set a maximum height for the table */ 
      background-color: #202841;
    }
  }
  .table-container-lifecycle-camera th { 
    background-color: #202841;
  }

  /* Change scrollbar color */
  .table-container-lifecycle-camera::-webkit-scrollbar {
    width: 13px; /* Width of the scrollbar */
  }
 
  .table-container-lifecycle-camera::-webkit-scrollbar-thumb {
    background: #334066; /* Color of the thumb */
  }
  .table-container-lifecycle-camera::-webkit-scrollbar-corner {
    background: #071d2c; /* Color of the thumb */
  }

  .modal-content  {
    padding: 0 0.5em!important;
  }

  .footer-lifecycle-container {
    margin-top: 12em;
  }
  @media (max-width: 600px) {
    .footer-lifecycle-container {
      margin-top: 2em;
      padding: 1em 0;
    }
}

.loading-refresh-lifecycle {
  height: 30rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .loading-refresh-lifecycle {
    height: 30rem;    
  }
}
@media (min-width: 600px) and (max-width: 1600px) {
  .loading-refresh-lifecycle {
    height: 25rem;    
  }
}
@media (min-width: 1601px) {
  .loading-refresh-lifecycle {
    height: 33rem
  }
}

.lifecycle-no-record-message {
  font-size: 1.2em;
  margin-left: 1em;
  color: #e08a20;
  text-align: center;
  margin-top: 5em;
  height: 10em;
}
@media (max-width: 600px) {
  .lifecycle-no-record-message {
    font-size: 0.9em;
  }
}



  