.sidebar-container {
    margin-top: -3.5em;
}

.home-container {
    height: auto;
    display: grid;
    place-items: center;
    margin-left: 15%;
    margin-top: 6em;
}
@media (max-width: 600px) {
    .home-container {
    height: 100vh;
    display: grid;
    place-items: center;
    margin-left: 0%;
    margin-top: 0em;
}
}
@media (min-width: 600px) and (max-width: 1600px) {
    .home-container {
        margin-top: 4em;
    }
}

.logo-container {
    margin-bottom: 10em;
    margin-top: 6em;
}
@media (max-width: 600px) {
    .logo-container {
        margin-bottom: 10em;
        margin-top: 4em;
        margin-left: 4.5rem;
    }
}

.home-image {
    width: 100%;
    padding: 1em 22em;
    margin-left: -3rem;
}
@media (max-width: 600px) {
    .home-image {
        padding: 0 1em;
    }
}

.home-btn {
    margin-top: -8%;
}
@media (min-width: 600px) and (max-width: 1600px) {
    .home-btn {
        margin-top: -4%;
    }
}
@media (max-width: 600px) {    
    .home-btn {
        margin-top: -140%;
    }
}

.home-btn-style {
   margin-top: 1em;
   background: linear-gradient(to right, #ff90c6, #594de5);
   border-radius: 0.4em;
   padding: 0.4em 1em;
   color: #fafafa;
   font-size: 1.1em;
   border: none;
   box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);    
}
@media (max-width: 600px) {
    .home-btn-style {
        padding: 0.4em 2.2em;
    }
}
.home-btn-style:hover {
    background: linear-gradient(to right, #594de5, #ff90c6);
}

.home-footer-container {
    margin-top: 10em;
}
@media (max-width: 600px) {
    .home-footer-container {
        margin-top: 1em;
        padding: 7.4em 0;
    }
}
@media (min-width: 600px) and (max-width: 1600px) {
    .home-footer-container {
        margin-top: 1em;
    }
}
@media (min-width: 1601px) {
    .home-footer-container {
        margin-top: 10em;
    }
}

.home-sidebar-small {
    margin-top: -20em;
}