.header-ul {
  display: flex;
  justify-content: flex-end;
}

ul.header-ul {
    list-style-type: none;    
    padding: 0;
    overflow: hidden;
    margin-left: 25%;
}

ul.header-ul li {
    float: right;
}

ul.header-ul li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    margin: 5px;
    font-size: large;
}

ul.header-ul li a:hover {
    background-color: #333 ;
}


@media (max-width: 600px) {
    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(153, 147, 147);
    }
}
.nav-container-img {
  height: 50%;
  width: 50%;
  padding: 0.6em;
}

.p-menu1{
    height: 100%;
 }  
 
 
 /* Hamburger */
 .hamburger1 {
   height: 25px;
   margin: 10px;
   display: -ms-grid;
   display: grid;
   grid-template-rows: repeat(3, 1fr);
   justify-items: end;
   z-index: 120;
   cursor: pointer;
 }
 
 .hamburger1 div {
   background-color: #e9426C;
   position: relative;
   width: 33px;
   height: 5px;
   margin-top: 3px;
   -webkit-transition: all 0.2s ease-in-out;
   transition: all 0.2s ease-in-out;
 }
 
 #toggle1 {
   display: none;
 }
 
 #toggle1:checked + .hamburger1 .top {
   -webkit-transform: rotate(-45deg);
           transform: rotate(-45deg);
   margin-top: 0.8rem;
 }
 
 #toggle1:checked + .hamburger1 .meat {
   -webkit-transform: rotate(45deg);
           transform: rotate(45deg);
   margin-top: -5px;
 }
 
 #toggle1:checked + .hamburger1 .bottom {
   -webkit-transform: scale(0);
           transform: scale(0);
 }
 
 #toggle1:checked ~ .menu1 {
   height: auto;
 }
 
 
 
 .menu1 {
   background-color: white;
   margin: 0;
   display: -ms-grid;
   display: grid;  
   padding: 0;
   list-style: none;
   clear: both;
   width: 8rem;   
   text-align: center;
   height: 0px;
   overflow: hidden;
   transition: height .4s ease;
   z-index: 120;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
 }
 
 .menu1 a:first-child {
   margin-top: 5px;
 }
 
 .menu1 a:last-child {
   margin-bottom: 0px;
 }
 
 .link1 {
   width: 100%;
   margin-left: 0;
   padding: 10px 0;
   font: 600 small 'Oswald', sans-serif;
   color: black;
   text-decoration: none;
 }
 
 .link1:hover {
   background-color: #F49523 !important;
   color: black;
   -webkit-transition: all 0.3s ease;
   transition: all 0.3s ease;
 }
  /* Login name in avatar form*/
  .avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #e08a20; /* Change this color to your preference */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    font-weight: bold;
    border-width: 0;
  }
  .avatar:hover {
    background-color: #8E338C;
  }

  .avatar-modal {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #8E338C; /* Change this color to your preference */
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    font-weight: bold;
    border-width: 0;
  }

  
/* business-token related starts */
.business-token-table-container {
  background-color: #384670;
  color: #fff;
  margin-top: 1rem;
  border-collapse: collapse;
  width: 100%;
  overflow-x: auto;
  max-height: 20em;
}

/* Change scrollbar color */
.business-token-table-container::-webkit-scrollbar {
  width: 13px; /* Width of the scrollbar */
}

.business-token-table-container::-webkit-scrollbar-thumb {
  background: #202841; /* Color of the thumb */
}

.business-token-table-header {
  background-color: #384670;
  color: #bbb9b9;
  border: 1px solid gray;
}

.business-token-table-column {
  border: 1px solid gray;
}

.business-token-table-btn-copy {
  text-decoration: none;
  border-width: 0;
  background-color: transparent;      
}

/* business-token related  ends*/

.bell-img-container {
   height: auto
}

.bell-img {
  height: 20px;
}

.login-link {
  text-decoration: none;
  background-color:  #F49523 ; 
  border-radius: 0.3em;
  font-size: 1em!important;
  color: black!important;
}

.login-link:hover {
  background-color: #8c5099!important;
  color: #fafafa!important;
}

ul.header-ul li a {
  padding: 10px 18px;
}


.modal-color-header {
  background: #384670;
  color: #fafafa;
}
.modal-color {
  background-image: url('../images/BackgroundImageLogout.png');
}

.header-modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.92em;
  color: #ccc;
}

.custom-close {
  background-color: transparent;
  border: none;
  font-size: large;
  margin-left: 1em;
  color: #fafafa;
}
.custom-close:hover{
  color: #F49523;
}

.signout-btn {
  border: none;
  background-color: transparent; 
}
.signout-btn:hover {
  font-weight: 500;
}
.signout-btn-text {
  font-size: 0.9em;
}

.mute-icon {
  height: 100%;  
  opacity: 0.9;
  background-color: transparent;
}

.mute-icon-small {
  height: 30px;
  margin-top: 10px;
  margin-right: 10px;
}

.mute-icon:hover {
 opacity: 1;
}


.mute-btn {
 padding: 0;
 margin: 0;
 border: none;
 background: transparent;
 height: 2.4rem;
}
@media (max-width: 600px) {
  .mute-btn {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    height: 1.5rem;
   }
}

/* Bell and mute container */
.bell-and-mute-container {
  margin-right: -80px;
}

/* Bell icon */
.bell-container {
  position: relative;
  display: flex;
  /* margin-right: 20px; */
}

.bell-icon {
  width: 38.4px;
  height: 2.3em; 
  padding: 0;
  margin:0;
  opacity: 0.9;
  cursor: pointer;
  margin-top: 4px;
  z-index: 999;
  position: relative;
  /* margin-right: -40px; */
}

.bell-icon-small {
  width: 30px;
  height: 30px; 
  opacity: 0.9;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: 1;
  /* margin-right: -50px; */
}

.bell-icon-badge {
  /* margin-right: -50px; */
  /* margin-left: 40px; */
  margin-top: 4px;
  margin-left: -12px;
  width: 20px; 
  height: 20px;
  background-color: red;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.bell-icon-badge-small {
  margin-top: 4px;
  margin-left: -12px;
  width: 15px; 
  height: 15px;
  background-color: red;
  color: white;
  font-size: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}